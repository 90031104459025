import { CURRENCY } from '@/const'

import ShopifyBase64 from '@/utils/shopifyBase64'
import normalize from '@/utils/normalize'

enum PRODUCT_TYPE {
  NORMAL = 'normal',
  SUIT = 'suit',
}

export enum VARIANT_TYPE {
  JACKET = 'jacket',
  PANT = 'pant',
}

interface IVariantOption {
  value: string
  label: string
}

export interface IVariant {
  id: number
  available: boolean
  compareAtPrice: number
  price: number
  quantityAvailable: number
  sku: string
  barcode: string
  opt: { [key: string]: IVariantOption }
}

interface IProductDimensions {
  [key: string]: string[]
}

interface IAvailableDimensions {
  [key: string]: {
    name: string
    available: boolean
  }[]
}

interface IPrismicLinks {
  [key: string]: {
    [key: string]: string
  }
}

export default class ShopifyProduct {
  id = 0
  image = ''
  currency: CURRENCY = CURRENCY.EUR

  price = 0
  compareAtPrice = 0

  priceRange: {
    min: number
    max: number
  } = {
    min: 0,
    max: 0,
  }

  variants: IVariant[] = []
  productDimensions: IProductDimensions = {}
  metafield: any = null
  totalInventory = 0

  productType = ''
  tags: string[] = []
  vendor = ''
  type: PRODUCT_TYPE = PRODUCT_TYPE.NORMAL

  prismicLinks: IPrismicLinks = {}
  startDate: Date | null = null
  endDate: Date | null = null

  constructor(data: any, type?: PRODUCT_TYPE) {
    this.id = ShopifyBase64.getId(data.id)

    this.productType = data.productType || ''
    this.tags = data.tags || ['']
    this.vendor = data.vendor || ''

    if (type) {
      this.type = type
    }

    if (data.priceRange) {
      this.priceRange.min = parseInt(data.priceRange.minVariantPrice.amount)
      this.priceRange.max = parseInt(data.priceRange.maxVariantPrice.amount)
    }

    if (data.variants) {
      if (data.variants.edges) {
        // Quand on appelle graphql
        data.variants.edges.forEach((variant: any) => {
          this.addVariant(variant.node)
        })
      } else {
        // Quand on reçoit une payload statique
        for (const variant of data.variants) {
          this.addVariantWithMap(variant, data.options)
        }

        // Cette payload ne possede pas priceRange et compareAtPriceRange
        this.price = data.variants.reduce((price: number, variant: any) => {
          if (price === 0 || price > variant.price) {
            return variant.price
          }
          return price
        }, 0)

        this.compareAtPrice = data.variants.reduce(
          (price: number, variant: any) => {
            if (price === 0 || price > variant.compareAtPrice) {
              return variant.price
            }
            return price
          },
          0
        )
      }
    }

    if (data.images && data.images.edges && data.images.edges.length > 0) {
      this.image = data.images.edges[0].node.originalSrc
    }

    if (data.metafield) {
      this.metafield = data.metafield.value
    }

    if (data.totalInventory) {
      this.totalInventory = Math.abs(parseInt(data.totalInventory))
    }

    if (this.isDimensionExist('group')) {
      this.price =
        this.variants
          .filter((v) => v.opt.group.label === VARIANT_TYPE.PANT)
          .reduce((price, variant) => {
            if (price === 0 || price > variant.price) {
              return variant.price
            }
            return price
          }, 0) +
        this.variants
          .filter((v) => v.opt.group.label === VARIANT_TYPE.JACKET)
          .reduce((price, variant) => {
            if (price === 0 || price > variant.price) {
              return variant.price
            }
            return price
          }, 0)

      this.compareAtPrice =
        this.variants
          .filter((v) => v.opt.group.label === VARIANT_TYPE.PANT)
          .reduce((compareAtPrice, variant) => {
            if (
              compareAtPrice === 0 ||
              compareAtPrice > variant.compareAtPrice
            ) {
              return variant.compareAtPrice
            }
            return compareAtPrice
          }, 0) +
        this.variants
          .filter((v) => v.opt.group.label === VARIANT_TYPE.JACKET)
          .reduce((compareAtPrice, variant) => {
            if (
              compareAtPrice === 0 ||
              compareAtPrice > variant.compareAtPrice
            ) {
              return variant.compareAtPrice
            }
            return compareAtPrice
          }, 0)
    } else {
      if (data.priceRange) {
        this.price = parseInt(data.priceRange.minVariantPrice.amount)
      }

      if (data.compareAtPriceRange) {
        this.compareAtPrice = parseInt(
          data.compareAtPriceRange.minVariantPrice.amount
        )
      }
    }

    if (data.metafields && data.metafields.some((m: any) => m && m !== null)) {
      for (const metafield of data.metafields) {
        if (metafield && metafield !== null) {
          if (metafield.namespace === 'prismic' && metafield.key === 'links') {
            this.prismicLinks = JSON.parse(metafield.value)
          }

          if (metafield.namespace === 'stock') {
            if (metafield.key === 'start_date_web') {
              const d = new Date(metafield.value)
              if (d.toString() !== 'Invalid Date') {
                this.startDate = d
              }
            }

            if (metafield.key === 'end_date') {
              const d = new Date(metafield.value)
              if (d.toString() !== 'Invalid Date') {
                this.endDate = d
              }
            }
          }
        }
      }
    }
  }

  /**
   * Transforme les selectedOptions d'une variante shopify en un objet simplifié
   * @param variant données venant de la graphql API (https://shopify.dev/api/storefront/2021-04/objects/productvariant)
   * @returns {Object} un objet simplifiant les selectedOptions name en Object.key et value pour value - https://shopify.dev/api/storefront/2021-04/objects/SelectedOption
   */
  private constructVariantOptions(variant: any) {
    const obj: { [key: string]: IVariantOption } = {}

    if (variant.selectedOptions) {
      for (const options of variant.selectedOptions) {
        const key = options.name.toLowerCase()

        if (!this.productDimensions[key]) {
          this.productDimensions[key] = []
        }

        if (key === 'group') {
          const opt =
            options.value === 'Veste' ||
            options.value === 'Jacket' ||
            options.value === 'Jacke'
              ? VARIANT_TYPE.JACKET
              : VARIANT_TYPE.PANT

          obj[key] = { value: normalize(opt), label: opt }
        } else {
          obj[key] = { value: normalize(options.value), label: options.value }
        }

        if (!this.productDimensions[key].includes(obj[key].value)) {
          this.productDimensions[key].push(obj[key].value)
        }
      }
    }

    return obj
  }

  /**
   * Prépare et ajoute une variante au tableau des variantes du produit
   * @param variant données venant de la graphql API (https://shopify.dev/api/storefront/2021-04/objects/productvariant)
   */
  addVariant(variant: any) {
    this.variants.push({
      opt: { ...this.constructVariantOptions(variant) },
      id: ShopifyBase64.getId(variant.id),
      available: variant.availableForSale,
      compareAtPrice: parseInt(variant.compareAtPrice.amount),
      quantityAvailable: variant.quantityAvailable
        ? variant.quantityAvailable
        : 0,
      price: parseInt(variant.price.amount),
      sku: variant.sku ? variant.sku : '',
      barcode: variant.barcode ? variant.barcode : '',
    })
  }

  addVariantWithMap(variant: any, mapOptions: any) {
    const opt: any = {}

    for (let i = 0; i < mapOptions.length; i++) {
      const key = mapOptions[i].name.toLowerCase()
      let value = mapOptions[i].values[variant.options[i]]

      if (key === 'group') {
        if (value === 'Veste' || value === 'Jacket' || value === 'Jacke') {
          value = VARIANT_TYPE.JACKET
        } else {
          value = VARIANT_TYPE.PANT
        }
      }

      opt[key] = { value: normalize(value), label: value }

      if (!this.productDimensions[key]) {
        this.productDimensions[key] = []
      }

      if (!this.productDimensions[key].includes(opt[key].value)) {
        this.productDimensions[key].push(opt[key].value)
      }
    }

    this.variants.push({
      id: variant.id,
      available: variant.available > 0,
      compareAtPrice: parseInt(variant.compareAtPrice),
      quantityAvailable: variant.available,
      price: parseInt(variant.price),
      sku: variant.sku ? variant.sku : '',
      barcode: variant.barcode ? variant.barcode : '',
      opt,
    })
  }

  /**
   * Est-ce que le produit est en mode stock
   * @returns {boolean} au moins une variante posséde un price et un compareAtPrice égal
   */
  static willBeInStock(p: ShopifyProduct): boolean {
    return p.variants.some(
      (variant) => variant.price === variant.compareAtPrice
    )
  }

  /**
   * Est-ce que le produit à au moins une variante en stock
   */
  get available(): boolean {
    return this.variants.some((v) => v.available)
  }

  get hasDates(): boolean {
    return this.startDate !== null || this.endDate !== null
  }

  get isSaleableAsStock(): boolean {
    if (this.startDate || this.endDate) {
      const now = new Date()

      if (this.startDate && this.endDate) {
        return this.startDate <= now && this.endDate >= now
      }

      if (this.startDate) {
        return this.startDate <= now
      }

      if (this.endDate) {
        return this.endDate >= now
      }
    }

    return false
  }

  /**
   * Est-ce que le produit posséde la dimension recherchée
   * @param dimension Le nom de la dimension
   * @returns {boolean}
   * @example
   * p.isDimensionExist
   */
  public isDimensionExist(dimension: string): boolean {
    return this.productDimensions[dimension] !== undefined
  }

  /**
   * Est-ce que la dimension posséde la valeur demandée
   * @param dimension Le nom de la dimension
   * @param value La valeur de la dimension
   * @returns {boolean}
   * @example
   * p.isDimensionHasValue('color', 'bleu')
   */
  public isDimensionHasValue(dimension: string, value: string): boolean {
    return this.variants.some(
      (v) => v.opt[dimension] && v.opt[dimension].value === value
    )
  }

  /**
   * Est-ce qu'au moins une variante est disponible en fonction d'une dimension
   * @param dimension Le nom de la dimension
   * @param name La valeur de la dimension
   * @returns {boolean}
   * @example
   * p.isDimensionAvailable('color', 'bleu')
   */
  public isDimensionAvailable(dimension: string, name: string): boolean {
    return this.variants.some(
      (v) => v.opt[dimension] && v.opt[dimension].value === name && v.available
    )
  }

  /**
   * Est-ce qu'au moins une variante est disponible en fonction d'un ensemble de dimension
   * @param dimensionsValue Les dimensions voulu
   * @returns {boolean}
   * @example
   * p.isMixedDimensionsAvailable({color: "bleu", "size": "XS"})
   */
  public isMixedDimensionsAvailable(dimensionsValue: {
    [key: string]: string
  }): boolean {
    const vv = this.variants.filter((variant) => {
      let is = true
      for (const key of Object.keys(dimensionsValue)) {
        if (
          variant.opt[key] !== undefined &&
          variant.opt[key].value !== dimensionsValue[key]
        ) {
          is = false
        }
      }

      return is
    })

    return vv.some((variant) => variant.available)
  }

  /**
   * Permet de savoir si le produit a du stock pour au moins une dimension du tableau de valeurs passé en paramètre
   * @param dimension la dimension
   * @param values une liste des valeurs de la dimension
   * @example
   * p.isAvailableForAtLeastOneDimensionValue('size', ['XS', 'S'])
   */
  public isAvailableForAtLeastOneDimensionValue(
    dimension: string,
    values: string[]
  ): boolean {
    if (values.length === 0) {
      return true
    }

    return this.variants.some((v) => {
      return (
        v.opt[dimension] &&
        values.includes(v.opt[dimension].value) &&
        v.available
      )
    })
  }

  /**
   * Filtre la liste des variantes en fonction d'une dimension
   * @param dimension la dimension
   * @param value la valeur
   * @returns Un tableau de variantes
   * @example
   * p.filterVariantsByDimension('color', 'bleu')
   */
  public filterVariantsByDimension(dimension: string, value: string) {
    const dd = []

    for (const variant of this.variants) {
      if (variant.opt[dimension] && variant.opt[dimension].value === value) {
        dd.push(variant)
      }
    }

    return dd
  }

  /**
   * Filtre la liste des variantes en fonction de plusieurs dimensions
   * @param dimensions Un tableau de dimension et leur valeur
   * @returns Un tableau de variantes
   * @example
   * p.filterVariantsByDimension([{dimension: 'color', value: 'bleu'}, {dimension: 'group', value: 'pant'}])
   */
  public filterVariantsByDimensions(
    dimensions: { dimension: string; value: string }[]
  ) {
    const dd = []

    for (const variant of this.variants) {
      let shouldBeAdded = true
      for (const d of dimensions) {
        if (
          !variant.opt[d.dimension] ||
          variant.opt[d.dimension].value !== d.value
        ) {
          shouldBeAdded = false
        }
      }
      if (shouldBeAdded) {
        dd.push(variant)
      }
    }

    return dd
  }

  /**
   * Retourne le prix d'un ensemble filtré de dimension
   * @param dimension la dimension
   * @param value la valeur
   * @returns {number} le plus petit prix des variantes du groupe de dimensions demandé
   */
  public priceByDimension(dimension: string, value: string): number {
    const vv = this.filterVariantsByDimension(dimension, value)

    return vv.reduce((price, variant) => {
      if (price === 0 || price > variant.price) {
        return variant.price
      }
      return price
    }, 0)
  }

  /**
   * Retourne le prix d'un ensemble filtré de dimension
   * @param dimension la dimension
   * @param value la valeur
   * @returns {number} le plus petit prix des variantes du groupe de dimensions demandé
   */
  public compareAtPriceByDimension(dimension: string, value: string): number {
    const vv = this.filterVariantsByDimension(dimension, value)

    return vv.reduce((price, variant) => {
      if (price === 0 || price > variant.compareAtPrice) {
        return variant.compareAtPrice
      }
      return price
    }, 0)
  }

  /**
   * Retourne les valeurs de dimensions en stock classée par dimension
   * @returns {IAvailableDimensions}
   */
  public getAvailableDimensions(): IAvailableDimensions {
    const availableVariants: IAvailableDimensions = {}

    for (const dimension of Object.keys(this.productDimensions)) {
      availableVariants[`${dimension}s`] = []

      for (const dimensionValue of this.productDimensions[dimension]) {
        availableVariants[`${dimension}s`].push({
          name: dimensionValue,
          available: this.isDimensionAvailable(dimension, dimensionValue),
        })
      }
    }

    return availableVariants
  }

  /**
   * Retourne une liste de dimension pour une autre dimension donnée
   * @param dimension la dimension initiale
   * @param value la valeur de la dimension initiale
   * @param requestedDimension la dimension associé à la dimension initiale
   * @return Un tableau de string
   */
  public getDimensionsByDimension(
    dimension: string,
    value: string,
    requestedDimension: string
  ): string[] {
    const dimensions: string[] = []
    for (const variant of this.variants) {
      if (
        variant.opt[dimension] &&
        variant.opt[dimension].value === value &&
        variant.opt[requestedDimension] &&
        !dimensions.includes(variant.opt[requestedDimension].value)
      ) {
        dimensions.push(variant.opt[requestedDimension].value)
      }
    }

    return dimensions
  }

  /**
   * Permet de mettre à jour les données du produit
   * @param data Données graphql produit shopify (https://shopify.dev/api/storefront/2021-04/objects/Product)
   */
  public update(data: any) {
    if (data.totalInventory) {
      this.totalInventory = Math.abs(parseInt(data.totalInventory))
    }

    if (data.metafield) {
      this.metafield = data.metafield.value
    }

    if (data.variants && data.variants.edges.length > 0) {
      for (const variantNode of data.variants.edges) {
        if (variantNode.node) {
          const variant = variantNode.node
          const productVariant = this.variants.find(
            (v) => v.id === ShopifyBase64.getId(variant.id)
          )

          if (productVariant) {
            productVariant.available = variant.availableForSale
            productVariant.price = parseInt(variant.price.amount)
            productVariant.compareAtPrice = parseInt(
              variant.compareAtPrice.amount
            )
            productVariant.sku = variant.sku

            productVariant.quantityAvailable = variant.quantityAvailable
              ? variant.quantityAvailable
              : 0
          }
        }
      }
    }
  }

  updateFromCollection(data: any) {
    if (data.variants) {
      for (const variant of data.variants) {
        const productVariant = this.variants.find((v) => v.id === variant.id)

        if (productVariant) {
          productVariant.available = variant.available > 0
          productVariant.quantityAvailable = variant.available
        }
      }
    }
  }

  hasPrismicUidFromContextAndLocale(context: string, locale: string) {
    return (
      this.prismicLinks &&
      this.prismicLinks[context] &&
      this.prismicLinks[context][locale.slice(0, 2)]
    )
  }

  public toJSON() {
    return Object.assign({}, this)
  }

  static fromJSON(data: any) {
    const p = new ShopifyProduct({ id: data.id }, data.type)

    p.tags = data.tags || []
    p.image = data.image || ''
    p.currency = data.currency || CURRENCY.EUR
    p.variants = data.variants || []
    p.productDimensions = data.productDimensions || {}
    p.metafield = data.metafield || null
    p.totalInventory = data.totalInventory || 0
    p.vendor = data.vendor || ''
    p.price = data.price || 0
    p.compareAtPrice = data.compareAtPrice || 0
    p.startDate = data.startDate || null
    p.endDate = data.endDate || null
    p.prismicLinks = data.prismicLinks || {}
    p.priceRange = data.priceRange || { min: 0, max: 0 }

    return p
  }
}
